import { axiosInstance } from "../setupInterceptors";

const endpointUrl = "/audit-communication";

export const updateCommunication = (id, data) =>
    axiosInstance
        .post(`${endpointUrl}/${id}`, data)
        .then((response) => response.data);

export const findAuditCommunication = (id) =>
    axiosInstance
        .get(`${endpointUrl}/${id}`)
        .then((response) => response.data);

export const closeCommunication = (id) =>
    axiosInstance
        .patch(`${endpointUrl}/${id}`,{})
        .then((response) => response.data);
