import {
  BatchTypes,
  CaseAuditTypes,
  CasesStatuses,
} from "../../misc/constants";

export const textModalActions = {
  editNote: "editNote",
  addNote: "addNote",
  changeStatus: "changeStatus",
  resolutionSend: "resolutionSend",
  replyNoteSend: "replyNoteSend",
  replyNoteEdit: "replyNoteEdit",
  auditConclusion: "auditConclusion",
  messageToClinicianSend: "messageToClinicianSend",
  messageToAuditorSend: "messageToAuditorSend",
};

export const changeStatusModalInfo = {
  title: "Reason for group review",
  description: "Describe why this case needs group review.",
};

export const editNotesModalInfo = {
  title: "Edit note",
  description: "Please enter your changes.",
};

export const addNoteModalInfo = {
  title: "Add note",
  description: "Enter your notes for this case",
};

export const SourceBreadcrumbTitle = {
  default: "Cases",
  clinicalAudit: "Clinical cases audit",
  specialAudit: "Special Audit",
};

export const GroupReviewIdFiledKeys = {
  [CaseAuditTypes.advice]: "adviceCliniciansGroupReview",
  [CaseAuditTypes.consultation]: "consCliniciansGroupReview",
};

export const AuditCaseStatuses = {
  [BatchTypes.audit]: [
    {
      status: CasesStatuses.compliant,
      disabled: false,
    },
    {
      status: CasesStatuses.groupReview,
      disabled: false,
    },
    {
      status: CasesStatuses.reviewNeeded,
      disabled: true,
    },
  ],
  [BatchTypes.groupReview]: [
    {
      status: CasesStatuses.forReflection,
      disabled: false,
    },
    {
      status: CasesStatuses.appropriate,
      disabled: false,
    },
    {
      status: CasesStatuses.forReflectionReplyNeeded,
      disabled: false,
    },
    {
      status: CasesStatuses.clinicalConcernReplyNeeded,
      disabled: false,
    },
    {
      status: CasesStatuses.groupReview,
      disabled: true,
    },
  ],
  [BatchTypes.specialAudit]: [
    {
      status: CasesStatuses.clinicalQuery,
      disabled: false,
    },
    {
      status: CasesStatuses.compliant,
      disabled: false,
    },
    {
      status: CasesStatuses.clinicalConcern,
      disabled: false,
    },
    {
      status: CasesStatuses.reviewNeeded,
      disabled: true,
    },
  ],
  [BatchTypes.docReview]: [
    {
      status: CasesStatuses.compliant,
      disabled: true,
    },
    {
      status: CasesStatuses.partiallyCompliant,
      disabled: true,
    },
    {
      status: CasesStatuses.noncompliant,
      disabled: true,
    },
    {
      status: CasesStatuses.reviewNeeded,
      disabled: true,
    },
  ],
  [BatchTypes.general]: [
    {
      status: CasesStatuses.compliant,
      disabled: true,
    },
    {
      status: CasesStatuses.partiallyCompliant,
      disabled: true,
    },
    {
      status: CasesStatuses.noncompliant,
      disabled: true,
    }
  ]
};

export const FinalGroupReviewStatuses = {
  [CasesStatuses.clinicalConcernResponseReceived]: [
    {
      status: CasesStatuses.clinicalConcernResponseReceived,
      disabled: true,
    },
    {
      status: CasesStatuses.clinicalConcern,
      disabled: false,
    },
  ],
  [CasesStatuses.forReflectionResponseReceived]: [
    {
      status: CasesStatuses.forReflectionResponseReceived,
      disabled: true,
    },
    {
      status: CasesStatuses.forReflection,
      disabled: false,
    },
  ],
};

export const FinalDocReviewsStatuses = [
  {
    status: CasesStatuses.clinicalConcernResponseReceived,
    disabled: true,
  },
  {
    status: CasesStatuses.clinicalConcern,
    disabled: false,
  },
];

export const BreadcrumbItems = {
  caseItem: {
    title: "Case",
    disabled: true,
  },
  defaultItem: {
    title: "Review",
    disabled: true,
  },
};
