import { PagesBreadCrumbTitles, RoutesMapping } from "../misc/constants";

const parseCurrentQuery = ({ title = null, path = null, prev }) => {
  if (!path && !(typeof path === "string")) return null;
  if (title && !Object.values(PagesBreadCrumbTitles).includes(title)) {
    return null;
  }
  const [breadcrumbTitle, id] = path.split(":");
  return {
    title: breadcrumbTitle,
    disabled: false,
    to: RoutesMapping[title || prev] + `/${id}` + `?prev=${prev}`,
  };
};

export const parseNavigationQuery = (data = {}) => {
  const { prev, path } = data;
  if (!Object.values(PagesBreadCrumbTitles).includes(prev)) return null;
  const currentItem = path ? parseCurrentQuery(data) : null;
  return [
    {
      title: prev,
      disabled: false,
      to: RoutesMapping[prev],
    },
    ...(currentItem ? [currentItem] : []),
  ];
};
