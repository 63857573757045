import { axiosInstance } from "../setupInterceptors";

const endpointUrl = "/audits";

export const getAuditById = (id) =>
  axiosInstance.get(`${endpointUrl}/${id}`).then((response) => response.data);

export const updateAudit = (id, data) =>
  axiosInstance
    .patch(`${endpointUrl}/${id}`, data)
    .then((response) => response.data);

export const createAdditionalAudit = (data) =>
  axiosInstance
    .post(`${endpointUrl}/additional-audit`, data)
    .then((response) => response.data);

export const updateAuditWithMessage = (id, data) =>
  axiosInstance
    .post(`${endpointUrl}/${id}/reply`, data)
    .then((response) => response.data);
