<template>
  <v-breadcrumbs :items="items" class="ma-0 pa-0">
    <template v-slot:item="{ item }">
      <v-breadcrumbs-item
        :link="!!item.to"
        :to="item.to"
        :disabled="item.disabled"
        exact
        class="text-subtitle-1 text-medium-emphasis"
      >
        {{ item.title }}
      </v-breadcrumbs-item>
    </template>
  </v-breadcrumbs>
</template>

<script>
export default {
  name: "Breadcrumbs",

  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
