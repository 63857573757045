import { defineStore } from "pinia";
import { getBatchById, closeBatch } from "@/services/batch";
import {
  getAuditById,
  updateAudit,
  updateAuditWithMessage,
} from "@/services/audit";
import { getAuditTemplate } from "../services/auditTemplates";
import {
  AuditTemplateTypes,
  BatchTypes,
  AuditStatuses,
  CasesStatuses,
} from "../misc/constants";
import {
  closeCommunication,
  findAuditCommunication,
  updateCommunication,
} from "@/services/auditCommunication";

const defaultStoreData = {
  auditIndex: 0,
  batchUnderReview: null,
  batchReport: null,
  auditTemplate: null,
  audit: null,
  auditMessages: null,
  communications: null,
  invalidClinicians: null,
  generalBatchProceeded: false,
  generalBatchStats: null,
  communicationOpenedBy: null,
  isCommunicationClosed: null,
};

export const useBatchReviewStore = defineStore("batchReview", {
  state: () => ({ ...defaultStoreData }),
  actions: {
    async changeAuditIndex(next) {
      this.auditIndex += next;
      await this.getCurrentAudit();
    },
    async startBatchReview(batch) {
      const { auditIds = [] } = batch;
      this.batchUnderReview = batch;
      const index = auditIds.findIndex(
        (idObj) => idObj?.status === AuditStatuses.open
      );
      this.auditIndex = index === -1 ? 0 : index;
      await Promise.all([this.getBatchAuditTemplate(), this.getCurrentAudit()]);
    },
    async getCurrentAudit() {
      if (!this.audits) return;
      const auditId = this.audits[this.auditIndex];
      await this.getAudit(auditId);
    },
    async getBatchAuditTemplate() {
      if (!this.batchUnderReview?.auditTemplateId) return;
      this.auditTemplate = await getAuditTemplate(
        this.batchUnderReview?.auditTemplateId
      );
    },
    reset() {
      Object.assign(this, defaultStoreData);
    },
    async endBatchReview() {
      const batchId = this.batchUnderReview._id;
      const result = await closeBatch(batchId);
      if (this.isDocReview) {
        this.invalidClinicians = result;
      }
    },
    storeBatchData() {
      const data = {
        batchUnderReview: this.batchUnderReview,
        attendees: this.attendees,
      };
      localStorage.setItem("batchReview", JSON.stringify(data));
    },
    initStoredBatchByCaseId(caseId, caseType) {
      const data = localStorage.getItem("batchReview");
      const parsedData = data ? JSON.parse(data) : null;
      if (parsedData) {
        const { batchUnderReview, attendees } = parsedData;
        const caseIndex = batchUnderReview.clinicianBasedCases.findIndex(
          (item) => item._id === caseId && item.caseType === caseType
        );
        if (caseIndex === -1) {
          return this.reset();
        }
        this.caseIndex = caseIndex;
        this.cases = batchUnderReview.clinicianBasedCases;
        this.batchUnderReview = batchUnderReview;
        this.attendees = attendees;
        return;
      }
      this.reset();
    },
    async updateCaseAudit(data) {
      const { caseGrade = null, caseEvaluation, auditMessage = null } = data;
      const isComplete = Object.values(caseEvaluation).some(
        (val) => val !== null
      );
      let payload = {
        caseEvaluation,
        isComplete,
      };

      if (this.reviewBatchType === BatchTypes.docReview) {
        const shouldSkipNotifyReceiver =
          this.audit?.caseGrade?.status === CasesStatuses.reviewNeeded &&
          caseGrade?.status === CasesStatuses.clinicalConcern;

        payload.caseGrade = caseGrade;

        if (auditMessage) {
          payload = {
            ...payload,
            auditMessage,
            shouldSkipNotifyReceiver,
          };
        }
      }
      this.audit = await updateAudit(this.audit._id, payload);
      this.initAuditMessages();
    },
    async getAudit(id) {
      this.audit = await getAuditById(id);
      await this.initAuditCommunications(this.audit?._id);
      this.initAuditMessages();
    },
    async updateAuditWithReply(data) {
      if (!this.audit) return;
      this.audit = await updateAuditWithMessage(this.audit._id, data);
      this.initAuditMessages();
    },
    async updateAuditCommunication(auditId, data) {
      const currentId = auditId || this.audit?._id;
      if (!currentId) return;
      await updateCommunication(currentId, data);
      await this.initAuditCommunications(currentId);
    },
    async closeAuditCommunication(auditId) {
      const currentId = auditId || this.audit?._id;
      if (!currentId) return;
      await closeCommunication(currentId);
      await this.initAuditCommunications(currentId);
    },
    async initAuditCommunications(auditId) {
      const c = await findAuditCommunication(auditId);
      this.communicationOpenedBy = c?.openedBy || null;
      this.isCommunicationClosed = !!c?.closedAt;
      this.communications = (c?.messages?.length && c?.messages) || null;
    },
    initAuditMessages() {
      this.auditMessages =
        (this.audit?.messages?.length && this.audit?.messages) || null;
    },
    setAuditMessage(newMessage) {
      if (!this.auditMessages) return;
      const index = this.auditMessages.findIndex(
        (message) => message._id === newMessage._id
      );
      if (index === -1) {
        this.auditMessages.push(newMessage);
        return;
      }
      this.auditMessages.splice(index, 1, newMessage);
    },
    async fetchTemplateFromAudit() {
      if (!this.audit) return;
      const auditId = this.audit.auditTemplateId;
      this.auditTemplate = await getAuditTemplate(auditId);
    },
    async fetchBatchFromAudit() {
      if (!this.audit) return;
      const batchId = this.audit.batchId;
      this.batch = await getBatchById(batchId);
    },
    checkIsAuditUpdateNeeded({ caseEvaluation, caseGrade, auditMessage }) {
      // Check of processed caseEvaluation if it filled with a new values will be updated
      const isEvaluationChanged = this.audit?.caseEvaluation
        ? Object.keys(this.audit.caseEvaluation).reduce((changed, key) => {
            if (caseEvaluation[key] === undefined) changed = false;
            if (caseEvaluation[key] !== this.audit.caseEvaluation[key])
              changed = true;
            return changed;
          }, false)
        : true;

      // For clinical Doc Audit we should also check caseGrade changes
      if (this.isDocReview) {
        // We should update audit with message
        if (auditMessage) return true;
        const { score, status } = this.audit?.caseGrade || {};
        const isGradeChanged =
          status !== caseGrade?.status || score !== caseGrade?.score;
        return isGradeChanged && isEvaluationChanged;
      }

      return isEvaluationChanged;
    },
    setGeneralBatchProceeded(val) {
      return this.generalBatchProceeded = val;
    },
    setGeneralBatchStats(data) {
      return this.generalBatchStats = {
        ...this.generalBatchStats,
        ...data
      };
    }
  },
  getters: {
    audits: ({ batchUnderReview }) =>
      batchUnderReview?.auditIds?.length
        ? batchUnderReview.auditIds.map((idObj) => idObj._id)
        : null,
    isBatchReviewActive: (state) => !!state.batchUnderReview,
    auditsLength() {
      return this.audits?.length || 0;
    },
    currentCaseUnderReview: ({ audit }) =>
      audit ? { _id: audit.caseId, caseType: audit.caseType } : null,
    reviewBatchType: (state) => state?.batchUnderReview?.type || null,
    auditTemplateType({ auditTemplate }) {
      if (!auditTemplate) return null;
      switch (auditTemplate.auditType) {
        case BatchTypes.docReview:
          return AuditTemplateTypes.score;
        case BatchTypes.general:
          return AuditTemplateTypes.general;
        default:
          return AuditTemplateTypes.target;
      };
    },
    isDocReview(state) {
      return (
        this.reviewBatchType === BatchTypes.docReview ||
        state.auditTemplate?.auditType === BatchTypes.docReview
      );
    },
    isSpecialAudit(state) {
      return (
        this.reviewBatchType === BatchTypes.specialAudit ||
        state.auditTemplate?.auditType === BatchTypes.specialAudit
      );
    },
    isLastCaseUnderReview(state) {
      return state.auditIndex + 1 === this.auditsLength;
    },
    isAuditDocVisible: (state) =>
      state.auditTemplate?.documentationFile &&
      state.auditTemplate?.auditType !== BatchTypes.docReview
  },
});
