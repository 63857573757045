import { axiosInstance } from "../setupInterceptors";

const endpointUrl = "/batches";

export const checkBatchName = (name) => {
  return axiosInstance
    .get(`${endpointUrl}/check-name`, { params: { name } })
    .then((response) => response.data);
};

export const closeBatch = (id) => {
  return axiosInstance
    .post(`${endpointUrl}/${id}/close`)
    .then((response) => response.data);
};

export const createBatch = (body) => {
  return axiosInstance
    .post(`${endpointUrl}/create`, body)
    .then((response) => response.data);
};

export const generateBatch = (body) => {
  return axiosInstance
    .post(`${endpointUrl}/generate`, body)
    .then((response) => response.data);
};

export const getBatchById = (id) => {
  return axiosInstance
    .get(`${endpointUrl}/${id}`)
    .then((response) => response.data);
};

export const getBatchCases = (id, params) => {
  return axiosInstance
    .get(`${endpointUrl}/${id}/cases`, { params })
    .then((response) => response.data);
};

export const getBatches = (params) => {
  return axiosInstance
    .get(endpointUrl, { params })
    .then((response) => response.data);
};

export const getBatchResult = (id) => {
  return axiosInstance
    .get(`${endpointUrl}/${id}/result`)
    .then((response) => response.data);
};

export const getGroupReviewSession = (id) => {
  return axiosInstance
    .get(`${endpointUrl}/${id}/group-review-session`)
    .then((response) => response.data);
};

export const loginGroupReviewAttendee = (body) => {
  return axiosInstance
    .post(`${endpointUrl}/group-review-session/attendees`, body)
    .then((response) => response.data);
};

export const logoutGroupReviewAttendees = (body) => {
  return axiosInstance
    .put(`${endpointUrl}/group-review-session/attendees`, body)
    .then((response) => response.data);
};

export const updateBatch = (batchId, body) => {
  return axiosInstance
    .put(`${endpointUrl}/${batchId}`, body)
    .then((response) => response.data);
};

export const batchDeleteBatches = (ids) => {
  return axiosInstance
    .delete(`${endpointUrl}/batch-delete`, {
      data: { ids }
    })
    .then((response) => response.data);
};

export const uploadReport = (batchId, body) => {
  const formData = new FormData();
  formData.append("file", body);
  return axiosInstance
    .put(`${endpointUrl}/${batchId}/report-file`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => response.data);
};

export const batchUnassignCases = (id, casesId) => {
  return axiosInstance
    .post(`${endpointUrl}/${id}/unassign-cases`, { casesId }).then((response) => response.data)
}

export const getGeneralBatchReport = (params) => {
  return axiosInstance
    .get(`${endpointUrl}/general-report`, { params })
    .then(response => response.data);
}
